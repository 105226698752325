import React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Link,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import EmailIcon from "@mui/icons-material/Email";
import WhiteLogo from "../img/logo/logo-05.png";
import { routes } from "../routes/page-routes";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function Navbar({ setDrawerOpen, drawerOpen }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="nav-bar">
        <Toolbar>
          <Typography variant="h6" component="div">
            <div className="logo">
              <img src={WhiteLogo} alt="Company Logo" />
            </div>
          </Typography>
          <Typography sx={{ flexGrow: 1 }}></Typography>
          <EmailIcon fontSize="medium" className="mail-icon" />
          <MenuIcon
            fontSize="large"
            className="menu-icon"
            onClick={() => setDrawerOpen(true)}
          />
        </Toolbar>
        <Drawer
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(39, 50, 53, 0.575) !important",
            },
            "& .MuiPaper-root": {
              backgroundColor: "rgb(0,0,0)",
              fontWeight: "600",
              color: "rgb(233,30,99)",
              width: "200px",
            },
          }}
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          className="menu-backdrop"
        >
          <div className="drawer-top">
            <img src={WhiteLogo} alt="Company Logo" />
          </div>
          <List>
            {routes.map((route) => (
              <ListItem key={route.route} disablePadding>
                <ListItemButton
                  component={Link}
                  to={route.route}
                  sx={{
                    "&:hover .MuiListItemText-primary": {
                      color: "white",
                    },
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={route.label}
                    sx={{
                      fontFamily: "Karma",
                      fontSize: "20px",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <div className="drawer-bottom">
            <FacebookIcon fontSize="medium" />
            <InstagramIcon fontSize="medium" />
            <TwitterIcon fontSize="medium" />
            <LinkedInIcon fontSize="medium" />
          </div>
        </Drawer>
      </AppBar>
    </Box>
  );
}

export default Navbar;
