import React from "react";
import Slide from "@mui/material/Slide";
import HomeIcon from "@mui/icons-material/Home";
import CameraIcon from "@mui/icons-material/CameraAlt";
import InfoColumns from "./info-columns";

function InfoPanel({ isOpen, onClose, openPanel2 }) {
  return (
    <Slide direction="up" in={isOpen}>
      <div className="panel1">
        <div className="arrow-up" onClick={onClose}>
          <HomeIcon />
        </div>

        <div className="info-panel-content">
          <InfoColumns></InfoColumns>
        </div>

        <div className="hover-arrow" onClick={openPanel2}>
          <CameraIcon />
        </div>
      </div>
    </Slide>
  );
}

export default InfoPanel;
