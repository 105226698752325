import Person from "../img/about/person.jpg";
import Secondary from "../img/about/secondary.jpeg";
import Tertiary from "../img/about/tertiary.jpeg";

function InfoColumns() {
  return (
    <div className="two-column-container">
      <div className="left-column">
        <img src={Person} alt="Main" className="main-image" />
        <img src={Secondary} alt="Small 1" className="small-image first" />
        <img src={Tertiary} alt="Small 2" className="small-image second" />
      </div>
      <div className="right-column">
        <p>
          What started as a wedding video company, founded by Justin Sanchez,
          Pneumatic Productions has evolved into a pinnacle of video production,
          blending artistry with expertise.
        </p>
        <div className="line-break"></div>
        <p>
          Situated in Buffalo, NY, we specialize in documentaries, spiritual
          videos, and educational content. Whether it is a story that needs to
          be told or the set-up of live video streaming, Pneumatic Productions
          is dedicated to meeting your video, sound, and lighting needs.
        </p>
      </div>
    </div>
  );
}

export default InfoColumns;
