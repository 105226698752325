import React from "react";

function Contact() {
  return (
    <div className="page-parent">
      Information needed: Name Email Telephone Description of service needed
      Expected delivery date
    </div>
  );
}

export default Contact;
