import React, { useState } from "react";
import Card from "./card";
import c1 from "../../img/cards/c1.jpg";
import c2 from "../../img/cards/c2.jpg";
import c3 from "../../img/cards/c3.jpg";
import c4 from "../../img/cards/c4.jpg";
import c5 from "../../img/cards/c5.jpg";
import c6 from "../../img/cards/c6.jpg";
import c7 from "../../img/cards/c7.jpg";
import c8 from "../../img/cards/c8.jpg";
import c9 from "../../img/cards/c9.jpg";
import c10 from "../../img/cards/c10.jpg";
import c11 from "../../img/cards/c11.jpg";
import c12 from "../../img/cards/c12.jpg";

function Cards() {
  const [cards, setCards] = useState([
    {
      image: c1,
      title: "Otter Creek Basin, VT",
      excerpt: "Excerpt for card 1",
    },
    {
      image: c2,
      title: "Illuminated Buildings",
      excerpt: "Excerpt for card 2",
    },
    {
      image: c3,
      title: "Code Editor On Manjaro Linux",
      excerpt: "Excerpt for card 3",
    },
    {
      image: c4,
      title: "Edgy Edit Of Antarctica",
      excerpt: "Excerpt for card 4",
    },
    {
      image: c5,
      title: "San Francisco On Fire",
      excerpt: "Excerpt for card 5",
    },
    {
      image: c6,
      title: "A Woman Is Under Arrest On PC",
      excerpt: "Excerpt for card 6",
    },
    {
      image: c7,
      title: "New Russian Military Church",
      excerpt: "Excerpt for card 7",
    },
    {
      image: c8,
      title: "Ghost Gunner Logo & QR Code",
      excerpt: "Excerpt for card 8",
    },
    {
      image: c9,
      title: "Pixel Art Mountain Landscape",
      excerpt: "Excerpt for card 9",
    },
    {
      image: c10,
      title: "Monochrome City Landscape",
      excerpt: "Excerpt for card 10",
    },
    {
      image: c11,
      title: "Plains In Japan At Night",
      excerpt: "Excerpt for card 11",
    },
    {
      image: c12,
      title: "Path Leading To Temple",
      excerpt: "Excerpt for card 12",
    },
  ]);

  return (
    <div className="card-container">
      <div className="card-grid">
        {cards.map((cardInfo, index) => (
          <Card key={index} info={cardInfo} />
        ))}
      </div>
    </div>
  );
}

export default Cards;
