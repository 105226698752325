import React from "react";

function Card({ info }) {
  return (
    <div className="card" style={{ backgroundImage: `url(${info.image})` }}>
      <div className="card-hover-info">
        <h3>{info.title}</h3>
        <p>{info.excerpt}</p>
      </div>
    </div>
  );
}

export default Card;
