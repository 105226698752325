import React from "react";

function SelectedWorks() {
  return (
    <div className="page-parent">
      <ul>
        <li>Ageless Wonders, Director of Photography and Assistant Producer</li>
        <li>
          Survive, Remember, Thrive: Armenian Traditions in Western New York,
        </li>
        <li>
          Cinematographer Lundin Mining: Portugal Community Video, Director and
          Editor
        </li>
        <li>
          Buffalo Documentary Project, Cinematographer and Assistant Producer
        </li>
        <li>
          Wedding Videography, Producer 
        </li>
        <h1>Need to create links and graphics.</h1>
        © Pneumatic Productions. All Rights Reserved.
      </ul>
    </div>
  );
}

export default SelectedWorks;
