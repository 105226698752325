import React from "react";

function About() {
  return (
    <div className="page-parent">
      <p>
        What started as a wedding video company, founded by Justin Sanchez,
        Pneumatic Productions has evolved into a pinnacle of video production,
        blending artistry with expertise. Situated in Buffalo, NY, we specialize
        in documentaries, spiritual videos, and educational content.
      </p>
      <p>
        Whether it is a story that needs to be told or the set-up of live video
        streaming, Pneumatic Productions is dedicated to meeting your video,
        sound, and lighting needs.
      </p>
      <h2>© Pneumatic Productions. All Rights Reserved.</h2>
    </div>
  );
}

export default About;
