import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import InfoIcon from "@mui/icons-material/Info";
import WorkIcon from "@mui/icons-material/Work";
import BuildIcon from "@mui/icons-material/Build"

export const routes = [
    { label: 'Home', route: '/', icon: HomeIcon }, 
    { label: 'About', route: '/about', icon: InfoIcon },
    { label: 'Contact Us', route: '/contact', icon: PhoneIcon },
    { label: 'Selected Works', route: '/selected-works', icon: WorkIcon }, 
    { label: 'Services', route: '/services', icon: BuildIcon }
  ];