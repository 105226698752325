import React from "react";

function Services() {
    return (
        <div className="page-parent">1. Short-Form Documentaries

        Dive deep into captivating narratives with our bespoke short-form documentaries. Tailored to resonate with your audience, we blend storytelling finesse with cinematic expertise to craft narratives that leave a lasting impression.
        
        2. YouTube Video Production
        
        Transform your voiceovers into compelling YouTube videos. Utilizing a rich palette of stock footage, dynamic graphics, and engaging text animations, we breathe life into your content, ensuring it captivates, educates, and entertains.
        
        3. Promotional & Advertisement Videos
        
        Elevate your brand's presence with our meticulously crafted promotional videos. From conceptualization to execution, we deliver content that not only showcases your brand's essence but also drives engagement and fosters connection.
        
        4. Live Streaming Consultation
        
        Step into the world of live streaming with confidence. Our expert consultation services for live streaming video set-ups ensure seamless integration of technology and creativity, guaranteeing a live experience that's immersive, reliable, and unforgettable.
        
        Let's Create Together
        
        At Pneumatic Productions, we're passionate about bringing visions to life. Connect with us today and let's embark on a collaborative journey to create content that inspires, informs, and captivates.
        
        © Pneumatic Productions. All Rights Reserved.
        </div>
    )
}

export default Services;