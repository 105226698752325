import React from "react";
import Slide from "@mui/material/Slide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Cards from "./cards/cards-parent";

function VideoPanel({ isOpen, onClose }) {
  return (
    <Slide direction="up" in={isOpen}>
      <div className="panel2">
        <div className="video-panel-content">
          <Cards></Cards>
        </div>

        <div className="arrow-up" onClick={onClose}>
          <FontAwesomeIcon icon={faChevronUp} />
        </div>
      </div>
    </Slide>
  );
}

export default VideoPanel;
