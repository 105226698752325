import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "../App.css";
import Navbar from "../components/navbar";
import InfoPanel from "../components/info-panel";
import VideoPanel from "../components/video-panel";

function Homepage() {
  const [showLogo, setShowLogo] = useState(false);
  const [panel1Open, setPanel1Open] = useState(false);
  const [panel2Open, setPanel2Open] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLogo(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="homepage">
      <Navbar setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} />

      <div className="hero">
        <CSSTransition in={showLogo} timeout={1000} classNames="logo-fade">
          <div className={`main-logo ${showLogo && "fade-in"}`} />
        </CSSTransition>
      </div>

      {!panel1Open && (
        <div
          className="hover-arrow"
          onClick={panel1Open ? setPanel2Open : setPanel1Open}
        >
          <FontAwesomeIcon icon={panel1Open ? faChevronDown : faChevronUp} />
        </div>
      )}

      <InfoPanel
        isOpen={panel1Open}
        onClose={() => setPanel1Open(false)}
        openPanel2={() => setPanel2Open(true)}
      />

      <VideoPanel isOpen={panel2Open} onClose={() => setPanel2Open(false)} />
    </div>
  );
}

export default Homepage;
