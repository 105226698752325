import './App.css';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Landing from "./pages/landing";
import About from "./pages/about";
import Contact from "./pages/contact";
import SelectedWorks from './pages/selected-works';
import Services from './pages/services';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Landing></Landing>}></Route>
          <Route path="/about" element={<About></About>}></Route>
          <Route path="/contact" element={<Contact></Contact>}></Route>
          <Route path="/selected-works" element={<SelectedWorks></SelectedWorks>}></Route>
          <Route path="/services" element={<Services></Services>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
